/**
 * Post-List for categories
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import PostProps from './CategoryNav.props';
import * as styles from './posts.module.scss';
import BlogPostCard from '../BlogPostCard';


const Posts = ({posts, withDate}) => (
    <ul className={styles.posts}
        style={{
            '--grid-count': Math.min(3, posts.length),
            '--grid-count-sm': Math.min(2, posts.length),
        }}
    >
        {posts && posts.map((post, key) => (
            <li className={styles.listItem} key={key}>
                <BlogPostCard post={post} withDate={withDate}/>
            </li>
        ))}
    </ul>
);

Posts.propTypes = {
    posts: PropTypes.arrayOf(PostProps).isRequired,
    withDate: PropTypes.bool,
};

Posts.defaultProps = {
    withDate: true,
};

export default Posts;
