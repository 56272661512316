/**
 * Post-List for categories
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';
import {ResponsiveImageProps} from '../ResponsiveImage';

const PostProps = PropTypes.shape({
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    dateString: PropTypes.string.isRequired,
    acf: PropTypes.shape({
        preview: PropTypes.shape({
            thumbnailId: PropTypes.shape(ResponsiveImageProps).isRequired,
        }).isRequired,
    }).isRequired,
}).isRequired;

export default PostProps;
