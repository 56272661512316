/**
 * Our blog post card component
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import {Link} from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './blog-post-card.module.scss';
import {CreateLocalLink, Translate} from '../../utilities';
import ArrowIcon from '../../images/arrow.inline.svg';
import {PostProps} from '../BlogCategory';
import ResponsiveImage from '../ResponsiveImage';

const BlogPostCard = ({post: {uri, acf, date, dateString, title}, withDate}) => (
    <Link className={styles.post} to={CreateLocalLink(uri)}>
        <div className={styles.imageContainer}>
            <ResponsiveImage className={styles.image} image={acf.preview.thumbnailId}/>
        </div>
        <div className={styles.content}>
            {withDate && <time className={styles.date} dateTime={date}>{dateString}</time>}
            <b className={styles.title}>{title}</b>
            <b className={styles.link}>{Translate('readNow')} <ArrowIcon className={styles.arrow}/></b>
        </div>
    </Link>
);

BlogPostCard.propTypes = {
    post: PostProps,
    withDate: PropTypes.bool,
};

BlogPostCard.defaultProps = {
    withDate: true,
};

export default BlogPostCard;
