/**
 * Component for showing the category-navigation
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import PropTypes from 'prop-types';

const CategoryProps = PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
}).isRequired).isRequired;

export default CategoryProps;
