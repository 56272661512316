// extracted by mini-css-extract-plugin
export var arrow = "posts-module--arrow--e6b18";
export var content = "posts-module--content--0683c";
export var date = "posts-module--date--25a56";
export var image = "posts-module--image--49cdb";
export var imageContainer = "posts-module--image-container--e9e5b";
export var link = "posts-module--link--fe83b";
export var listItem = "posts-module--list-item--a5366";
export var post = "posts-module--post--6550f";
export var posts = "posts-module--posts--8bb0e";
export var title = "posts-module--title--9fea3";