/**
 * Our blog-post-page
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Seo, {SeoShape} from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {ResponsiveImageProps} from 'components/ResponsiveImage';
import {LangShape} from 'components/Languages';
import Hero from 'blocks/Hero';
import {CategoryNav, CategoryProps, Posts} from 'components/BlogCategory';
import {BlogPostCardProps} from 'components/BlogPostCard/';
import Pagination from '../../../components/Pagination';

const BlogCategory = ({data}) => {
    const [page, setPage] = useState(1);

    const {
        name,
        uri,
        seo,
        language,
        translations,
        acf,
        children,
        posts,
    } = data;

    const postsPerPage = 9;
    let parent = data.parent && data.parent.node ? data.parent.node : null;

    let firstPostIndex =  (page - 1) * postsPerPage;
    let currentPosts = posts.nodes.slice(firstPostIndex, firstPostIndex + postsPerPage);

    const {
        heroImage,
        withDate,
    } = !!parent ? parent.acf : acf;

    const title = !!parent ? parent.name : name;
    const subCategories = !!parent ? parent.children.nodes : children.nodes;

    return (<>
        <Seo seoData={seo} translations={translations} language={language} url={uri}/>
        <Header siteTitle={seo.title} translations={translations} language={language}/>
        <main>
            <Hero mediaType={'image'} title={title} image={heroImage}/>
            <CategoryNav categories={subCategories}/>
            <Posts posts={currentPosts} withDate={withDate}/>
            <Pagination maxElements={posts.nodes.length} postsPerPage={postsPerPage} setPage={setPage} currentPage={page}/>
        </main>
        <Footer/>
    </>);
};

const AcfProps = {
    acf: PropTypes.shape({
        heroImage: PropTypes.shape({
            ...ResponsiveImageProps,
        }).isRequired,
        withDate: PropTypes.bool,
    }).isRequired,
};

const ChildrenProps = {
    children: PropTypes.shape({
        nodes: CategoryProps,
    }).isRequired,
};

BlogCategory.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        seo: SeoShape.isRequired,
        ...LangShape,
        ...AcfProps,
        parent: PropTypes.shape({
            name: PropTypes.string.isRequired,
            uri: PropTypes.string.isRequired,
            ...AcfProps,
            ...ChildrenProps,
        }),
        ...ChildrenProps,
        posts: PropTypes.shape({
            nodes: PropTypes.arrayOf(BlogPostCardProps).isRequired,
        }).isRequired,
    }).isRequired,
};

export default BlogCategory;
