/**
 * Component for showing the category-navigation
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import {Link} from 'gatsby';
import CategoryProps from './CategoryNav.props';
import {CreateLocalLink} from '../../utilities';
import * as styles from './category-nav.module.scss';

const CategoryNav = ({categories}) => {
    if (!categories) {
        return '';
    }

    return (<nav>
        <ul className={styles.categoryNav}>
            {categories && categories.map((category, key) => (<li className={styles.navItem} key={key}>
                <Link className={styles.catLink}
                    to={CreateLocalLink(category.uri)}
                    activeClassName={styles.active}
                >{category.name}</Link>
            </li>))}
        </ul>
    </nav>);
};

CategoryNav.propTypes = {
    categories: CategoryProps,
};

export default CategoryNav;
